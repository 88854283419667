<template>
  <b-overlay :show="$apollo.loading || loadingSave" rounded="sm">
    <div class="card" v-if="!systemTransport && !$apollo.loading">
      <div class="card-body text-center">ไม่พบข้อมูล</div>
    </div>
    <b-form
      v-if="systemTransport"
      ref="form"
      class="form"
      @submit.stop.prevent="save"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="text-dark-75 font-weight-bold mb-8">
            รายการพัสดุ
            <span
              v-if="systemTransport.in_order && systemTransport.in_order.length"
              class="text-success"
              >(ฝากสั่ง)</span
            >
            <span v-if="systemTransport.trackingWarehouse" class="text-success"
              >({{ systemTransport.trackingWarehouse.name }})</span
            >
          </h3>
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="form-group row mb-2" v-if="searchUserList">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ผู้ใช้งาน</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <v-select
                    searchable
                    :filterable="false"
                    placeholder="กรุณารหัสผู้ใช้"
                    required
                    :options="searchUserList.records"
                    label="username"
                    @search="searchUser"
                    v-model="systemTransport.user"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!systemTransport.user"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ขนส่งระหว่างประเทศจีน</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-dropdown
                    v-model="systemTransport.ship_by"
                    :state="validateState('ship_by')"
                    :text="
                      systemTransport.ship_by === 'ek'
                        ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                        : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                    "
                  >
                    <b-dropdown-item
                      value="ek"
                      @click="systemTransport.ship_by = 'ek'"
                    >
                      ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                    </b-dropdown-item>
                    <b-dropdown-item
                      value="ship"
                      @click="systemTransport.ship_by = 'ship'"
                    >
                      ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ประเภทสินค้า</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-select
                    v-model="systemTransport.userLevelPrice"
                    :state="validateState('userLevelPrice')"
                    required
                    :options="
                      systemUserLevel &&
                        (systemTransport.ship_by === 'ek'
                          ? systemUserLevel.shippingEk
                          : systemUserLevel.shippingShip)
                    "
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >โกดังพัสดุ</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-select
                    v-model="systemTransport.trackingWarehouse"
                    :state="validateState('trackingWarehouse')"
                    :options="trackingWarehouses"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ล็อต</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-input type="number" v-model="systemTransport.lotId" />
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-remarkPurchaseOrder"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เลข PO</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-input
                    type="text"
                    v-model="systemTransport.remarkPurchaseOrder"
                  />
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ตีลังไม้</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <span class="switch switch-sm mt-1">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        v-model="systemTransport.guarantee"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ค่าตีลัง</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    v-model="systemTransport.price_wooden_box"
                    type="number"
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เข้าโกดังจีน</label
                >
                <div class="col-lg-5 col-sm-5 col-12 notranslate">
                  <b-input-group>
                    <b-form-datepicker
                      type="date"
                      placeholder="ยังไม่เลือกวันที่"
                      locale="th-TH"
                      label-no-date-selected=""
                      v-model="systemTransport.timeline.send_to_warehouse_china"
                      reset-button
                    />
                    <template v-slot:append>
                      <b-button
                        @click="
                          systemTransport.timeline.send_to_warehouse_china = null
                        "
                      >
                        <inline-svg
                          src="/media/svg/icons/Navigation/Close.svg"
                        />
                      </b-button>
                    </template>
                  </b-input-group>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ออกโกดัง</label
                >
                <div class="col-lg-5 col-sm-5 col-12 notranslate">
                  <b-input-group>
                    <b-form-datepicker
                      type="date"
                      placeholder="ยังไม่เลือกวันที่"
                      locale="th-TH"
                      label-no-date-selected=""
                      v-model="systemTransport.timeline.send_out_china"
                    />
                    <template v-slot:append>
                      <b-button
                        @click="systemTransport.timeline.send_out_china = null"
                      >
                        <inline-svg
                          src="/media/svg/icons/Navigation/Close.svg"
                        />
                      </b-button>
                    </template>
                  </b-input-group>
                </div>
              </div>
              <div class="row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ถึงโกดังปลายทาง</label
                >
                <div class="col-lg-5 col-sm-5 col-12 notranslate">
                  <b-input-group>
                    <b-form-datepicker
                      type="date"
                      placeholder="ยังไม่เลือกวันที่"
                      locale="th-TH"
                      label-no-date-selected=""
                      v-model="systemTransport.timeline.send_to_warehouse_th"
                    />
                    <template v-slot:append>
                      <b-button
                        @click="
                          systemTransport.timeline.send_to_warehouse_th = null
                        "
                      >
                        <inline-svg
                          src="/media/svg/icons/Navigation/Close.svg"
                        />
                      </b-button>
                    </template>
                  </b-input-group>
                </div>
              </div>
              <div class="row" v-if="systemTransport.serviceCube">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ฝากสั่งคิดเพิ่ม คิวละ</label
                >
                <div class="col-lg-5 col-sm-4 col-12 m-desc-lg">
                  {{ systemTransport.serviceCube }}
                </div>
              </div>
              <div class="row" v-if="systemTransport.serviceKg">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ฝากสั่งคิดเพิ่ม กิโลละ</label
                >
                <div class="col-lg-5 col-sm-4 col-12 m-desc-lg">
                  {{ systemTransport.serviceKg }}
                </div>
              </div>
              <div class="row" v-if="systemTransport.payshipping_doc">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เลขที่บิล</label
                >
                <div class="col-lg-5 col-sm-4 col-12 m-desc-lg">
                  {{ systemTransport.payshipping_doc }}
                </div>
              </div>
              <div class="form-group row mb-2 mt-2">
                <b-form-group
                  label-cols="12"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  content-cols="8"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="รูปภาพสินค้า"
                  label-for="input-category"
                  label-class="fw-bolder"
                >
                  <b-overlay :show="loadingImageList">
                    <vue-upload-multiple-image
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      dragText="ลากรูปภาพ (หลายภาพ)"
                      browseText="(หรือ) เลือก"
                      primaryText="ค่าเริ่มต้น"
                      :showPrimary="false"
                      markIsPrimaryText="ตั้งเป็นค่าเริ่มต้น"
                      popupText="ภาพนี้จะแสดงเป็นค่าเริ่มต้น"
                      dropText="วางไฟล์ของคุณที่นี่..."
                      :data-images="systemTransport.file_tracking || []"
                    ></vue-upload-multiple-image>
                  </b-overlay>
                </b-form-group>
              </div>
              <div class="table-responsive">
                <table class="table table-head-bg">
                  <thead>
                    <tr>
                      <th>เลขพัสดุ (จีน)</th>
                      <th class="text-center">จำนวน</th>
                      <th class="text-center">KG/CBM</th>
                      <th class="text-center">น้ำหนัก</th>
                      <th class="text-center">กว้าง</th>
                      <th class="text-center">ยาว</th>
                      <th class="text-center">สูง</th>
                      <th class="text-center">ราคา</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b-form-input
                          id="input-transportNumber"
                          v-model="systemTransport.transportNumber"
                          :state="nameState"
                          required
                          placeholder=""
                        ></b-form-input>
                      </td>
                      <td class="text-center">
                        <b-form-input
                          type="number"
                          :state="nameState"
                          min="1"
                          required
                          v-model="systemTransport.amount"
                          class="mb-2"
                        ></b-form-input>
                      </td>
                      <td class="text-center text-nowrap">
                        <div>
                          KG:
                          {{ " " }}
                          {{
                            calculateWeight(
                              systemTransport.size &&
                                systemTransport.size.weight,
                              systemTransport.amount
                            ) || "-"
                          }}
                        </div>
                        <div>
                          CBM:
                          {{ " " }}
                          {{
                            calculateCube(
                              systemTransport.size,
                              systemTransport.amount
                            ) || "-"
                          }}
                        </div>
                      </td>
                      <td style="min-width: 70px">
                        <b-form-input
                          v-model="systemTransport.size.weight"
                        ></b-form-input>
                      </td>
                      <td style="min-width: 70px">
                        <b-form-input
                          v-model="systemTransport.size.width"
                        ></b-form-input>
                      </td>
                      <td style="min-width: 70px">
                        <b-form-input
                          v-model="systemTransport.size.long"
                        ></b-form-input>
                      </td>
                      <td style="min-width: 70px">
                        <b-form-input
                          v-model="systemTransport.size.height"
                        ></b-form-input>
                      </td>
                      <td class="text-center text-nowrap">
                        <div v-if="systemTransport.trackingPrice">
                          {{
                            systemTransport.trackingPrice.priceName === "kg"
                              ? "กิโล : "
                              : "ขนาด : "
                          }}
                          {{
                            format_comma(
                              systemTransport.trackingPrice.priceValue
                            )
                          }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4 mb-2"
                style="min-width: 140px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                บันทึกรายการขนส่ง
              </button>
              <button
                type="button"
                @click="deleteItem(systemTransport)"
                class="btn btn-danger mr-4 mb-2"
                style="min-width: 140px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span>
                ลบรายการ
              </button>
              <router-link
                to="/admin/forwarder"
                class="btn btn-secondary mb-2"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import bigDecimal from "js-big-decimal";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_TRACKING,
  UPDATE_TRACKING,
  DELETE_TRACKING
} from "@/graphql/admin/transport.js";
import { SEARCH_USERS } from "@/graphql/admin/user";
import { GET_USER_LEVEL } from "@/graphql/admin/user_level";
import debounce from "debounce";
import {
  API_URL,
  calculateCube,
  calculateWeight,
  imageUrlApi,
  IMAGE_URL,
  WEBSITE
} from "@/config";
import VSelect from "vue-select";
import dayjs from "dayjs";
import axios from "axios";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { GET_TRACKING_WAREHOUSE_LIST } from "@/graphql/tracking_warehouse";

export default {
  mixins: [validationMixin],
  name: "admin_forwarder_detail",
  components: { VSelect, VueUploadMultipleImage },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/admin/forwarder" },
      { title: `รายการพัสดุ` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    document.title = `รายการพัสดุ #${id} | ${WEBSITE}`;
    return {
      nameState: null,
      loadingImageList: false,
      loadingSave: false,
      id: Number(id),
      trackingPrice: {},
      systemTransport: {
        transportNumber: "",
        user: null,
        amount: 0,
        ship_by: "ek",
        remark: "",
        guarantee: false,
        price_wooden_box: 0,
        userLevelPrice: "",
        remarkPurchaseOrder: "",
        file_tracking: [],
        size: {
          weight: 0,
          width: 0,
          long: 0,
          height: 0
        },
        timeline: {},
        trackingPrice: {},
        trackingWarehouse: {}
      }
    };
  },
  validations: {
    systemTransport: {
      user: {
        userId: {
          required
        }
      },
      transportNumber: {
        required
      },
      file_tracking: {},
      amount: {
        required
      },
      ship_by: {
        required
      },
      lotId: {},
      remark: {},
      guarantee: {},
      price_wooden_box: {},
      userLevelPrice: {
        required
      },
      remarkPurchaseOrder: {},
      size: {},
      trackingWarehouse: {},
      timeline: {
        send_to_warehouse_china: {},
        send_out_china: {},
        send_to_warehouse_th: {}
      }
    }
  },
  methods: {
    calculateCube,
    calculateWeight,
    searchUser(search, loading) {
      this.search = search;
      loading(true);
      this.searchDelay(loading, search, this);
    },
    searchDelay: debounce((loading, search, vm) => {
      vm.$apollo.queries.searchUserList.refetch({ search }).then(() => {
        loading(false);
      });
    }, 350),
    calculatePrice() {
      const { size, amount, userLevelPrice } = this.systemTransport;
      if (!size) {
        this.systemTransport.trackingPrice = {
          priceName: "kg",
          priceValue: 0
        };
        return;
      }
      const cube = calculateCube(size, amount);
      const weight = size ? size.weight || 0 : 0;

      const serviceKg = this.systemTransport.serviceKg || 0;
      const serviceCube = this.systemTransport.serviceCube || 0;

      const trackingWarehouse = this.systemTransport.trackingWarehouse;
      const warehouseKg = trackingWarehouse?.warehouseKg || 0;
      const warehouseCube = trackingWarehouse?.warehouseCube || 0;

      const rateKg = userLevelPrice
        ? (userLevelPrice.userLevelDetailPriceKg || 0) + serviceKg + warehouseKg
        : 0;
      const rateCube = userLevelPrice
        ? (userLevelPrice.userLevelDetailPriceCube || 0) +
          serviceCube +
          warehouseCube
        : 0;

      const priceKg = bigDecimal.multiply(rateKg, weight) * amount;
      const priceCube = bigDecimal.multiply(rateCube, cube);

      if (priceCube > priceKg) {
        this.systemTransport.trackingPrice = {
          priceName: "cube",
          priceValue: priceCube
        };
        return;
      }
      this.systemTransport.trackingPrice = {
        priceName: "kg",
        priceValue: priceKg
      };
    },
    validateStateUser(name) {
      const { $dirty, $error } = this.$v.systemTransport["user"][name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.systemTransport[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.systemTransport.$touch();
      if (this.$v.systemTransport.$anyError) {
        return;
      }
      const { systemTransport } = this.$v;
      const transportNumber = systemTransport.transportNumber.$model;
      const ship_by = systemTransport.ship_by.$model;
      const userId = systemTransport.user.userId.$model;
      const amount = systemTransport.amount.$model;
      const remark = systemTransport.remark.$model;
      const guarantee = systemTransport.guarantee.$model;
      const price_wooden_box = systemTransport.price_wooden_box.$model;
      const userLevelPrice = systemTransport.userLevelPrice.$model;
      const remarkPurchaseOrder = systemTransport.remarkPurchaseOrder.$model;
      const trackingWarehouse = systemTransport.trackingWarehouse.$model;
      const file_tracking = systemTransport.file_tracking.$model.map(
        x => x.path
      );
      const size = systemTransport.size.$model;
      const lotId = systemTransport.lotId.$model;
      const send_to_warehouse_china =
        systemTransport.timeline.send_to_warehouse_china.$model;
      const send_out_china = systemTransport.timeline.send_out_china.$model;
      const send_to_warehouse_th =
        systemTransport.timeline.send_to_warehouse_th.$model;

      size.weight = Number(size.weight);
      size.width = Number(size.width);
      size.long = Number(size.long);
      size.height = Number(size.height);

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        _id: this.systemTransport._id,
        transportId: this.systemTransport.transportId,
        transportNumber,
        ship_by,
        amount: Number(amount),
        remark,
        guarantee,
        price_wooden_box: Number(price_wooden_box) || 0,
        size: size,
        userId,
        lotId: Number(lotId) || 0,
        file_tracking,
        remarkPurchaseOrder,
        timeline: {
          send_to_warehouse_china: send_to_warehouse_china
            ? dayjs(send_to_warehouse_china)
            : "",
          send_out_china: send_out_china ? dayjs(send_out_china) : "",
          send_to_warehouse_th: send_to_warehouse_th
            ? dayjs(send_to_warehouse_th)
            : ""
        },
        userLevelPrice: {
          userLevelDetailName: userLevelPrice.userLevelDetailName,
          userLevelDetailPriceCube: userLevelPrice.userLevelDetailPriceCube,
          userLevelDetailPriceKg: userLevelPrice.userLevelDetailPriceKg,
          userLevelDetailShipping: userLevelPrice.userLevelDetailShipping
        },
        trackingWarehouse: trackingWarehouse?._id || null
      };
      this.loadingSave = true;
      const {
        data: { systemUpdateTransport }
      } = await this.$apollo.mutate({
        mutation: UPDATE_TRACKING,
        variables: {
          input: input
        }
      });
      this.loadingSave = false;
      if (!systemUpdateTransport.isSuccess) {
        this.$message.error(systemUpdateTransport.message);
      } else {
        this.$message.success(systemUpdateTransport.message);
        this.$apollo.queries.systemTransport.refetch();
      }
    },
    async deleteItem(item) {
      const confirm = await Swal.fire({
        title: "ยืนยันลบข้อมูล ?",
        icon: "warning",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { systemRemoveTransport }
      } = await this.$apollo.mutate({
        mutation: DELETE_TRACKING,
        variables: {
          id: item.transportId
        }
      });
      if (!systemRemoveTransport.isSuccess) {
        this.$message.error(systemRemoveTransport.message);
      } else {
        this.$message.success(systemRemoveTransport.message);
        this.$router.push("/admin/forwarder");
      }
    },
    uploadImageSuccess(formData) {
      this.loadingImageList = true;
      axios
        .post(`${API_URL}/upload/temp2`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          const { data } = res;
          this.loadingImageList = false;
          if (!this.systemTransport.file_tracking) {
            this.systemTransport.file_tracking = [];
          }
          if (data) {
            for (const image of this.systemTransport.file_tracking) {
              image.highlight = 0;
            }
            this.systemTransport.file_tracking.push({
              path: `${IMAGE_URL}${data.data}`,
              default: this.systemTransport.file_tracking.length === 0 ? 1 : 0,
              highlight: 1
            });
          }
        });
    },
    uploadFunction(e) {
      let file = e.target.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.loadingImage = true;
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          this.loadingImage = false;
          const { data } = res;
          if (data) {
            document.getElementById("getFile").value = "";
            const urlImage = data.data;
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index; // Get the cursor position
            quill.insertEmbed(length, "image", urlImage); // Insert image res.url for the image address returned by the server
            quill.setSelection(length + 1); // adjust the cursor to the end
          }
        });
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();
      formData.append("file", file);

      return axios.post(`${API_URL}/upload/cloud`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    beforeRemove(index, done) {
      done();
      if (this.systemTransport.file_tracking[index].default) {
        this.systemTransport.file_tracking.splice(index, 1);
        if (this.systemTransport.file_tracking.length > 0) {
          this.systemTransport.file_tracking[0].default = 1;
        }
      } else {
        this.systemTransport.file_tracking.splice(index, 1);
      }
      if (this.systemTransport.file_tracking[index]) {
        this.systemTransport.file_tracking[index].highlight = 1;
      }
    },
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  apollo: {
    systemTransport: {
      query: GET_TRACKING,
      variables() {
        return {
          id: this.id
        };
      },
      update: ({ systemTransport }) => {
        if (systemTransport.timeline) {
          const {
            send_to_warehouse_china,
            send_out_china,
            send_to_warehouse_th
          } = systemTransport.timeline;
          if (send_to_warehouse_china) {
            systemTransport.timeline.send_to_warehouse_china = dayjs(
              send_to_warehouse_china
            ).toDate();
          }
          if (send_out_china) {
            systemTransport.timeline.send_out_china = dayjs(
              send_out_china
            ).toDate();
          }
          if (send_to_warehouse_th) {
            systemTransport.timeline.send_to_warehouse_th = dayjs(
              send_to_warehouse_th
            ).toDate();
          }
        }
        if (systemTransport.file_tracking) {
          systemTransport.file_tracking = systemTransport.file_tracking.map(
            (x, i) => ({
              path: imageUrlApi(x),
              default: i === 0 ? 1 : 0,
              highlight: i === 0 ? 1 : 0
            })
          );
        }

        return systemTransport;
      }
    },
    searchUserList: {
      query: SEARCH_USERS,
      variables() {
        return {
          search: this.search
        };
      }
    },
    trackingWarehouses: {
      query: GET_TRACKING_WAREHOUSE_LIST,
      update: ({ trackingWarehouses }) => {
        return (
          trackingWarehouses?.records.map(item => ({
            text: `${item.name} (kg: ${item.warehouseKg ||
              0}, cbm: ${item.warehouseCube || 0})`,
            value: item
          })) || []
        );
      }
    },
    systemUserLevel: {
      query: GET_USER_LEVEL,
      skip() {
        return (
          !this ||
          !this.systemTransport ||
          !this.systemTransport.user ||
          !this.systemTransport.user.userId
        );
      },
      variables() {
        return {
          userId:
            this.systemTransport &&
            this.systemTransport.user &&
            this.systemTransport.user.userId
        };
      },
      update: ({ systemUserLevel }) => {
        if (!systemUserLevel) {
          return {
            shippingShip: [],
            shippingEk: []
          };
        }
        systemUserLevel.shippingEk = (systemUserLevel.shippingEk || []).map(
          x => ({
            text: `${x.userLevelDetailName} (kg: ${x.userLevelDetailPriceKg}, cbm: ${x.userLevelDetailPriceCube})`,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        systemUserLevel.shippingShip = (systemUserLevel.shippingShip || []).map(
          x => ({
            text: `${x.userLevelDetailName} (kg: ${x.userLevelDetailPriceKg}, cbm: ${x.userLevelDetailPriceCube})`,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        return systemUserLevel;
      }
    }
  },
  watch: {
    ["systemTransport.userLevelPrice"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.amount"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.weight"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.width"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.long"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.height"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.trackingWarehouse"]: function() {
      this.calculatePrice();
    }
  }
};
</script>
